import {getFormConfig} from "./form";

export const getTableColumn = vm => {
  const formConfigList = getFormConfig().filterFormConfig;
  const list = formConfigList.map(item => {
    return {
      title: item.label,
      field: item.key,
      sortable: true
    }
  })

  const fixedList = [
    { type: 'seq', title: '序号', width: '60' },
    { type: 'checkbox', width: '60' },
    { type: 'radio', title: '单选', width: '60' },
  ];

  const opList = [
    {
      field: "edit",
      title: "操作",
      render: true,
      fixed: 'right',
      width: '130',
      cellRender: {name: 'render'},
      render: (h, {data}) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            {/* <el-button type="text" onClick={() => { vm.clientEdit(row, 'clientStatus') }}>启用</el-button> */}
            <el-button type="text" onClick={() => { vm.clientEdit(row, 'clientDetail') }}>详情</el-button>
            {/* <el-button type="text" onClick={() => { vm.clientEdit(row, 'clientEdit') }}>修改</el-button> */}
          </div>
        );
      }
    },
  ]
  
  return {
    tableColumn:[...fixedList, ...list, ...opList ]
  }
}