export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        key: "Created_By",
        label: "创建人",
        type: "input",
        placeholder: "请输入创建人",
        checked: false,
        tabGroup: 'Synthesis',
        tabGroupName: '综合',
      },
      { key: "Creation_Date", label: "创建日期", type: "input", placeholder: "请输入创建日期", checked: false, tabGroup: 'Synthesis', tabGroupName: '综合' },
      { key: "Last_Update_Date", label: "最后更新日期", type: "input", placeholder: "请输入最后更新日期", checked: false, tabGroup: 'Synthesis', tabGroupName: '综合' },
      { key: "Last_Updated_By", label: "最后更新人", type: "input", placeholder: "请输入最后更新人", checked: false, tabGroup: 'Synthesis', tabGroupName: '综合' },
    ]
  }
}