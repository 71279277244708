import { getTableColumn } from './config/table';

export default {
  name: 'Mock',
  data() {
    const a = getTableColumn(this).tableColumn;
    return {
      tableColumn: a,
      tableData: [
        { Last_Updated_By: 10001, Created_By: 'Test1', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃', edit: '' },
        { Last_Updated_By: 10002, Created_By: 'Test2', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou', edit: '' },
        { Last_Updated_By: 10003, Created_By: 'Test3', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai', edit: '' },
        { Last_Updated_By: 10004, Created_By: 'Test4', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai', edit: '' },
        { Last_Updated_By: 10005, Created_By: 'Test5', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃', edit: '' },
        { Last_Updated_By: 10006, Created_By: 'Test6', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou', edit: '' },
        { Last_Updated_By: 10007, Created_By: 'Test7', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai', edit: '' },
        { Last_Updated_By: 10008, Created_By: 'Test8', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai', edit: '' },
        { Last_Updated_By: 10009, Created_By: 'Test9', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃', edit: '' },
        { Last_Updated_By: 100010, Created_By: 'Test10', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou', edit: '' },
        { Last_Updated_By: 100011, Created_By: 'Test11', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai', edit: '' },
        { Last_Updated_By: 100012, Created_By: 'Test12', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai', edit: '' }
      ]
    }
  },
  methods: {
    handleClick(params) {
      console.log('handleClick', params)
    },
    handleDetail(params) {
      console.log('handleDetail', params)
    },
    handlePageChange({ currentPage, pageSize }) {
      console.log(currentPage, pageSize)
    }
  }
}